import firebase from 'firebase/app'
import 'firebase/firestore'

const userRef = firebase.firestore().collection('users')

const getInformation = async (userId) => {
    const userDoc = await userRef.doc(userId).get()
    if (userDoc.exists) {
        let user = userDoc.data()
        return user
    }
    return undefined
}

const getFullname = async (userId) => {
    if (!userId) return "JLegal Thailand"
    const userDoc = await userRef.doc(userId).get()
    if (userDoc.exists) {
        let user = userDoc.data()
        return `${user.firstname} ${user.lastname}`
    }
    return "JLegal Thailand"
}

export {
    getInformation,
    getFullname
}