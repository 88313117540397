<template>
    <div class="container">
        <Breadcrumb class="my-4" />
        <div class="my-5">
            <h1 class="fw-bold text-capitalize text-darkblue">{{$t('title', article.title) ? $t('title', article.title) : article.title.en}}</h1>
            <div class="small text-muted mb-4">{{$t('timestamp')}} {{article.postDate}}</div>
        </div>
        <img v-if="!!bannerUrl" class="w-100 rounded-3 border" :src="bannerUrl" alt="Article's main banner">
        <div class="my-5">
            <div class="ck-content" v-html="$t('body', article.body) ? $t('body', article.body) : article.body.en"></div>
        </div>
        <div class="d-flex border-top my-4 py-4">
            <i class="fas fa-user-circle fa-3x"></i>
            <div class="ps-3 my-auto">
                <h6 class="mb-0">{{author.firstname}} {{author.lastname}}</h6>
                <span class="text-muted small">{{author.description}}</span>
            </div>
        </div>
    </div>
</template>
<i18n>
{
    "en": {
        "title": "{en}",
        "body": "{en}",
        "timestamp": "Post at"
    },
    "th": {
        "title": "{th}",
        "body": "{th}",
        "timestamp": "โพสต์เมื่อ"
    }
}
</i18n>
<script>
import firebase from 'firebase/app'
import 'firebase/firestore'

import { getSingleArticle, viewCounter } from '@/helpers/news'
import * as author from '@/helpers/user'

export default {
    data() {
        return {
            bannerUrl: null,
            article: {
                title: {en: "", th: ""},
                body: {en: "", th: ""}
            },
            author: {}
        }
    },
    async created() {
        let articleId
        try {
            // Get information from url path
            let articlePath = decodeURIComponent(this.$route.path.split('/').pop())
            articleId = articlePath.split('-').pop()

            // Retrieve article information from firestore
            let article = await getSingleArticle(articleId)

            // Mixing in useful information
            article.postDate = this._formatDateRelation(article.postDate.seconds)
            this.author = await author.getInformation(article.authorId)
            this.article = article
            this.bannerUrl = article.banner.url
        } catch (error) {
            console.log(error)
            this.$router.push({name: 'Error404'})
        }

        try {
            viewCounter(articleId)
        } catch (error) {}
    }
}
</script>